/*  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
} */

/*.video-container video {
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

/* .video-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

} */

@media screen and (max-width:400px) {
    .video-container {
        position: absolute;
        top: 50%;
        left: 50%;
        /*  width: min(1200px, 100vw);
        height: min(550px, 100vh); */
        width: 100%;
        transform: translate(-50%, -50%);
        overflow: hidden;
    }



    video {

        width: 100% !important;
        height: auto !important;
    }
}

@media screen and (min-width:401px) {
    .video-container {

        width: 100%;
        /* min(1200px, 100vw); */
        height: 100%;
        /* min(550px, 100vh); */
        overflow: hidden
    }



    video {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);

        width: 100% !important;
        height: 100% !important;
    }
}